.hero {
  margin-top: 100px;
  margin-bottom: 100px; 
}

.hero .hero-cta {
  text-align: center;
}

.hero-cta small {
  display: block;
  color:#888;
  margin-top: 10px;
}

.hero .hero-button {
  margin-top: 20px;
}

.hero .col-img {
  margin-right: 50px;
  margin-left: 50px;
}

.how-it-works {
  border-top: 1px solid #EEE;
  border-bottom: 1px solid #EEE;
   padding-top: 50px;
  padding-bottom: 20px;
  text-align: center;
}

.how-it-works .row {
  padding-top: 40px;
  padding-bottom: 40px;
}


.bottom-cta {
  margin: 70px;
  text-align: center;
}

.bottom-cta small {
  display: block;
  color:#888;
  margin-top: 10px;
}

/* Mobile */
@media (max-width: 576px) {
  .hero {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px; 
  }

  .how-it-works img {
    padding-left: 100px;
    padding-right: 100px;
  }

  .how-it-works .row {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .bottom-cta {
    margin: 30px;
  }
}