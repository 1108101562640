
.buttonSend {
  margin-top: 40px;
  text-align: center;
}

.payments h2 {
  font-size: 1.5rem;  
}

.payments h3 {
  font-size: 1.3rem;  
}


.payments p {
  font-size: 0.9rem;
}

.monthBox {
  margin-top: 10px;
  margin-bottom: 20px;
}