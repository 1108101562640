.buttonBar {
/*  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;*/
  text-align: center;
}


.steps {
  margin-top: 10px;
  margin-bottom: 10px;
  
  text-align: center;

  font-size: 1.2rem;
}



.form h2 {
  font-size: 1.5rem;  
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}


.form p {
  text-align: center;
  font-size: 0.9rem;
}