#wrapper {
  min-height: calc(100vh - 150px);
  max-width: 890px;
}

.navbar {
  margin-top: 20px;
  margin-bottom: 30px;
  padding-left: 0;
  padding-right: 0;
}

footer {  
  margin-top: 50px;
  height: 100px;
  background: rgb(227, 238, 255);
  border-top: 1px solid rgb(245, 249, 255);
  padding: 30px;
  text-align: center;
  color: gray;
}

.loading {
  text-align: center;
}