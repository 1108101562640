
.field {
  display: flex;
  flex-direction: row;
  border: 1px solid red;
  margin-right: 10px;
  margin-left: 10px;
}

.formField {
  flex: 1;
}

.field .unit {
  margin-left: 10px;
}